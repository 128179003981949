.content {
  display: grid;
  grid-row-gap: 16px;
}

.subtitle {
  display: flex;
  align-items: center;
  font-size: 1rem;

  > a {
    margin-left: 1rem;
  }
}