@import '../../../../styles/variables';
@import '../../../../styles/media';

.title {
  font-weight: bold;
  font-size: 110%;
}

.tabBodyWrapper {
  padding: 1rem;
  background-color: rgb(248, 248, 248);
}

.editingSection {
  color: $primary;
}

.fields {
  @include media('<=small') {
    display: grid;
    width: 100%;
    max-width: 50em;
    column-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-areas: 'leadId' 'warning';
  }

  .leadId {
    grid-area: leadId;
  }

  .warning {
    grid-area: warning;
  }
}

.spacer {
  flex-grow: 1;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding-top: 1rem;

  > :not(.spacer) {
    min-width: 7em;
    margin: 4px 8px;

    @include media('<=small') {
      width: 100%;
    }
  }
}