@import './styles/variables';

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 16px;
  height: 100%;
  line-height: 1.5;
  font-family: map-get($fonts, roboto);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
