@import '../../../../styles/variables';
@import '../../../../styles/media';

.title {
  font-weight: bold;
  font-size: 110%;
}

.error {
  font-size: 12px;
  color: red;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding: 8px 24px 16px 24px;

  > :not(.spacer) {
    min-width: 7em;
    margin: 4px 8px;

    @include media('<=small') {
      width: 100%;
    }
  }

  :first-child {
    margin-right: 0;
  }
}
