.greenText {
  color: green;
}

.redText {
  color: red;
}

.extraList {
  margin-left: 1rem;
  list-style: circle;
  font-size: 0.85rem;
}