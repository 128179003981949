@import '../../../../styles/media';

.fields {
  display: grid;
  width: 100%;
  max-width: 50em;
  grid-template-columns: 20em 20em;
  grid-template-areas: 'type amount';
  column-gap: 16px;

  @include media('<=small') {
    grid-template-columns: 1fr;
    grid-template-areas: 'type' 'amount';
  }

  .amount {
    grid-area: amount;
  }

  .type {
    grid-area: type;
  }
}
