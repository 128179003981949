.products {
  margin-bottom: 1rem;
}

.title {
  font-weight: bold;
  font-size: 110%;
  margin: 0;
}

.summary {
  display: flex;
  justify-content: space-between;

  .onroads {
    padding: 1rem;
    display: flex;
    flex: 1;
    border: solid lightgrey 1px;
    border-radius: 0.25rem;
    flex-direction: column;
    margin-inline-end: 1rem;
    
    h1 {
      margin: 0 0 .5rem 0;
      font-size: 1rem;
    }
  }

  .onroads > ul > li {
    padding: .5rem 0;
    display: flex;
    justify-content: space-between;
  }

  .totals {
    border: solid lightgrey 1px;
    border-radius: 0.25rem;
    display: flex;
    flex: 1;
    flex-direction: column;

    li {
      padding: .5rem 1rem .5rem 1rem;
      display: flex;
      justify-content: space-between;
    }

    li:first-child {
      padding-top: 1rem;
    }
    
    li:nth-last-child(2) {
      padding-bottom: 1rem;
    }

    li:last-child {
      background-color: lightgrey;
      padding: 1rem;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      display: flex;
      flex: 1;
      align-items: flex-end;
    }
  }
}
 

