@import '../../../../styles/media';

.searchOptions {
  display: grid;
  width: 100%;
  max-width: 50em;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: '. . . . .';
}

.spacer {
  flex-grow: 1;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding-top: 1rem;

  > :not(.spacer) {
    min-width: 7em;
    margin: 4px 8px;

    @include media('<=small') {
      width: 100%;
    }
  }
}

.tableContainer {
  margin-top: 1rem;
  background-color: #fff;
  display: grid;

  .tableCellLabel {
    border-bottom: none;
    background-color: #dcdcdc;
    padding: 0.5rem 0.625rem 0 0.625rem;
  }

  .tableCellFilter {
    padding: 0.625rem 0.5rem 0.625rem;
    background-color: #dcdcdc;

    :global(.MuiFormControl-marginNormal) {
      margin-top: 0;
    }
  }

  .tableRowVehicle {
    cursor: pointer;
  }

  .tableCell {
    padding: 0.5rem 0.625rem;
  }
}