@import '../../styles/variables';

.actions {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.filterbar {
  width: 20rem;
}

.newitem {
  width: 12rem;
}

.table {
  margin-top: 1rem;
  
  .number {
    width: 8rem;
  }
}

.tablehead {
  background-color: lightgray;
}

.row {
  cursor: pointer;

  &:hover {
    td {
      color: $primary;
    }
  }
}

.nodata {
  text-align: center;
}
