.progress-container {
  display: grid;
  grid-template-areas: 'content';
}

.button {
  grid-area: content;
}

.progress {
  grid-area: content;
  justify-self: center;
  align-self: center;
}
