@import '../../../styles/variables';

.table {
  margin-top: 1rem;
}

.tablehead {
  background-color: lightgray;
}

.row {
  cursor: pointer;

  &:hover {
    td {
      color: $primary;
    }
  }
}

.noquotes {
  text-align: center;
}
