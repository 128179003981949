@import '../../../styles/variables';

.readonly::before {
  content: '';
  background-color: $information;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.1;
}

.textField, .selectField {
  background-color: #fff;
}