
.fields {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
  grid-template-rows: 4rem;
  column-gap: 1rem;
}

.ordertype {
  padding-top: 1rem;
}
