@import '../../../../styles/variables';
@import '../../../../styles/media';

.title {
  font-weight: bold;
  font-size: 110%;
}

.expand {
  color: $primary;
}

.spacer {
  flex-grow: 1;
}

.tablehead {
  background-color: lightgrey;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding: 8px 24px 16px 24px;

  > :not(.spacer) {
    min-width: 7em;
    margin: 4px 8px;

    @include media('<=small') {
      width: 100%;
    }
  }
}

.form {
  width: 100%;

  input {
    font-size: 14px !important;
  }
}

.field {
  width: 100%;
}

.description {
  width: 35%;
}

.qty {
  width: 10%;
}
.total {
  width: 10%;
}
.price {
  width: 14%;
}
.soldBy {
  width: 10%;
}

.cell {
  padding-top: 0.2rem;
  padding-bottom: 0;
  border-bottom: 0;
}

.spacer {
  flex-grow: 1;
}

.validationRow td {
  border-style: none;
}

.editingSection {
  color: $primary;
}
