.tablehead {
  background-color: lightgrey;
}

.description {
  width: 45%;
}

.qty {
  width: 5%;
}