.root {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: auto auto 1fr; // Make the title auto, so that it breaks after the audit when on small screens
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    'breadcrumb audit audit'
    'title subtitle menu'
    'content content content';
}

.breadcrumb {
  grid-area: breadcrumb;
  margin-bottom: 0.75em;

  a {
    display: flex; // Show icons and text in line
  }
}

.title {
  grid-area: title;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.subtitle {
  grid-area: subtitle;
  align-self: center;
}

.audit {
  grid-area: audit;
  text-align: right;
}

.content {
  grid-area: content;
  padding: 1em 0;
}

.menu {
  grid-area: menu;
  align-self: center;
  text-align: right;
  
  button {
    padding: 0;
  }
}
