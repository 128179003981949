@import '../../../../styles/media';

.fields {
  @include media('<=small') {
    display: grid;
    width: 100%;
    max-width: 50em;
    column-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-areas: 'assetNo' 'warning';
  }

  .assetNo {
    grid-area: assetNo;
  }

  .warning {
    grid-area: warning;
  }
}

.spacer {
  flex-grow: 1;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding-top: 1rem;

  > :not(.spacer) {
    min-width: 7em;
    margin: 4px 8px;

    @include media('<=small') {
      width: 100%;
    }
  }
}