@import '../../../../styles/media';

:global(.MuiExpansionPanelDetails-root) {
  flex-direction: column;
}

.fields {
  display: grid;
  width: 100%;
  max-width: 50em;
  grid-template-columns: repeat(5, minmax(10em, 20em)) 1fr;
  grid-template-rows: 4rem;
  grid-template-areas:
    'condition condition . . .'
    'assetNo make model year idleLocation'
    'registration floorPlan price manufacturersRebate discount';
  column-gap: 1rem;

  @include media('<=medium') {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'condition condition'
      'assetNo .'
      'make model'
      'year idleLocation'
      'registration floorPlan'
      'manufacturersRebate discount'
      'price .'
  }

  @include media('<=small') {
    grid-template-columns: 1fr;
    grid-template-areas: 'condition' 'assetNo' 'make' 'model' 'year' 'idleLocation' 'registration' 'floorPlan' 'price' 'manufacturersRebate' 'discount';
  }

  .condition {
    grid-area: condition;
  }

  .make {
    grid-area: make;
  }

  .model {
    grid-area: model;
  }

  .year {
    grid-area: year;
  }

  .registration {
    grid-area: registration;
  }

  .price {
    grid-area: price;
  }

  .manufacturersRebate {
    grid-area: manufacturersRebate;
  }

  .discount {
    grid-area: discount;
  }

  .assetNo {
    grid-area: assetNo;
  }

  .floorPlan {
    grid-area: floorPlan;
  }

  .searchBtnWrapper {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .idleLocation {
    grid-area: idleLocation;
  }
}
