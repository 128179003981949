@import '../../../styles/variables';

.container {
  pointer-events: none; // Let clicks through the container
  top: 0;
  left: 0;
  margin: 0;

  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .toast {
    pointer-events: initial; // Make clicks count on the Toasts themselves
    cursor: pointer;
    font: inherit;

    width: 40em;
    max-width: 100vw;
    margin-bottom: 0.5em;

    &.error {
      color: $on-error;
      background-color: $error;

      :global .progress {
        background: $on-error;
      }
    }

    &.warning {
      color: $on-warning;
      background-color: $warning;

      :global .progress {
        background: $on-warning;
      }
    }

    &.information {
      color: $on-information;
      background-color: $information;

      :global .progress {
        background: $on-information;
      }
    }

    &.success {
      color: $on-success;
      background-color: $success;

      :global .progress {
        background: $on-success;
      }
    }
  }
}

.notification {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 180%;
  margin: 0 0.2em;
}
