@import '../../../../styles/variables';
@import '../../../../styles/media';

.title {
  font-weight: bold;
  font-size: 110%;
}

.tabBodyWrapper {
  padding: 1rem;
  background-color: rgb(248, 248, 248);
}

.editingSection {
  color: $primary;
}

