@import '../../../styles/variables';
@import '../../../styles/media';

.title {
  font-weight: bold;
  font-size: 110%;
}

.spacer {
  flex-grow: 1;
}

.tablehead {
  background-color: lightgrey;
}

.actions {
  display: flex;
  justify-content: flex-end;
  grid-area: actions;

  > :not(.spacer) {
    min-width: 7em;
    margin: 4px 8px;

    @include media('<=small') {
      width: 100%;
    }
  }
}

.description {
  width: 45%;
}

.qty {
  width: 5%;
}

.belowMinSellRequest {
  display: grid;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1rem;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'requestInfo requestInfo'
    'quoteSummary quoteSummary'
    'notes totals'
    'notes totals'
    'notes totals'
    'escalate escalate'
    'actions actions';

  @include media('<=small') {
    grid-template-columns: 1fr;
    grid-template-areas:
    'requestInfo'
    'quoteSummary'
    'totals'
    'notes'
    'escalate'
    'actions';
  }

  padding-bottom: 3rem;
}

.quoteSummary {
  grid-area: quoteSummary;
  @include media('<=small') { 
    overflow-x: scroll;
  }
}

.totals {
  grid-area: totals;

  display: flex;
  flex-direction: column;

  ul {
    border: solid lightgrey 1px;
    border-radius: 0.25rem;
  }
  li {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .priceSummary {
    background-color: lightgrey;
  }
}

.escalate {
  grid-area: escalate;
}

.notes {
  grid-area: notes;
  display: grid;
  gap: 0.5rem;
}

.requestInfo {
  grid-area: requestInfo;
}

.statusMessage {
  background-color: antiquewhite;
  padding: 2px 4px;
  margin-left: 30px;
}