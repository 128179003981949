$background: var(--background);
$on-background: var(--on-background);
$primary: var(--primary);
$on-primary: var(--on-primary);
$default: var(--default);
$on-default: var(--on-default);
$error: var(--error);
$on-error: var(--on-error);
$warning: var(--warning);
$on-warning: var(--on-warning);
$information: var(--information);
$on-information: var(--on-information);
$success: var(--success);
$on-success: var(--on-success);

$fonts: (
  roboto: (
    'Roboto',
    'Segoe UI',
    'Helvetica',
    'Arial',
    'sans-serif',
  ),
);
