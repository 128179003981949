@import '../../../../styles/media';

.fields {
  display: grid;
  width: 100%;
  max-width: 50em;
  grid-template-columns: repeat(4, minmax(10em, 20em)) 1fr;
  grid-template-areas:
    'make model year . .'
    'registration kms price . .';
  column-gap: 16px;

  @include media('<=medium') {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'make model'
      'year registration'
      'kms price';
  }

  @include media('<=small') {
    grid-template-columns: 1fr;
    grid-template-areas: 'make' 'model' 'year' 'registration' 'kms' 'price';
  }

  .make {
    grid-area: make;
  }

  .model {
    grid-area: model;
  }

  .year {
    grid-area: year;
  }

  .registration {
    grid-area: registration;
  }

  .kms {
    grid-area: kms;
  }

  .price {
    grid-area: price;
  }
}
