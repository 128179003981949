@import '../../../styles/variables';

.tab {
  margin-top: 2.5rem;
}

.tabs {
  background-color: $on-default;
  box-shadow: none;
  color: $default;
}