@import '../../../../styles/variables';
@import '../../../../styles/media';

.title {
  font-weight: bold;
  font-size: 110%;
}

.spacer {
  flex-grow: 1;
}

.details {
  display: grid;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1rem;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'products products'
    'notes totals';
}

.products {
  grid-area: products;
}

.totals {
  grid-area: totals;
  border: solid lightgrey 1px;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;

  li {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    justify-content: space-between;
  }

  li:last-child {
    background-color: lightgrey;
    margin-top: auto;
    padding-bottom: 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

.notes {
  grid-area: notes;
}

.warnings {
  display: grid;
  gap: 0.5rem;
}