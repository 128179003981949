@import '../../../../styles/variables';
@import '../../../../styles/media';

.actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding: 8px 0 16px 0;

  > :not(.spacer) {
    min-width: 7em;
    margin: 4px 8px;

    @include media('<=small') {
      width: 100%;
    }
  }

  :first-child {
    margin-right: 0;
  }
}
