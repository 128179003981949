.menu-img {
  height: 1.8em;
  border-radius: 50%;
}

.menuLink {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  cursor: pointer;
}