.actions {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'additional-actions standard-actions';

  > :not(:last-child) {
    margin-left: 8px;
  }

  .additional-actions {
    display: flex;
    grid-area: additional-actions;
  }

  .standard-actions {
    display: flex;
    grid-area: standard-actions;
    flex-direction: row-reverse;
  }
}

.confirmation-dialog-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
